import { graphql, useStaticQuery } from 'gatsby';

export const useAboutUsText = () => {
  const { directusAboutNew } = useStaticQuery(graphql`
    query AboutUsNew {
      directusAboutNew {
        page_title
        page_description
        header_image {
          title
          filename_disk
        }
        header
        header_text
        header_mission_text
        story_image {
          title
          filename_disk
        }
        story_header
        story_text
        story_who
        values_header
        value_1_image {
          title
          filename_disk
        }
        value_1_header
        value_1_text
        value_2_image {
          title
          filename_disk
        }
        value_2_header
        value_2_text
        value_3_image {
          title
          filename_disk
        }
        value_3_header
        value_3_text
      }
    }
  `);
  return directusAboutNew;
};
