import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import { useAboutUsText } from '@hooks/directusHooks/useAboutUsText';

import styles from './about.module.scss';

const AboutUsPage = () => {
  const t = useAboutUsText();
  return (
    <Layout>
      <SEO title={t.page_title} description={t.page_description} />
      <section className={styles.aboutUs}>
        <div className={styles.left}>
          <img src={`${process.env.GATSBY_MEDIA_URL}${t.header_image.filename_disk}`} alt={t.header_image.title} />
        </div>
        <div className={styles.right}>
          <h1>{t.header}</h1>
          <hr />
          <img src={`${process.env.GATSBY_MEDIA_URL}${t.header_image.filename_disk}`} alt={t.header_image.title} />
          <p>{t.header_text}</p>
          <p>{t.header_mission_text}</p>
        </div>
      </section>
      <section className={styles.story}>
        <h1>{t.story_header}</h1>
        <hr />
        <div className={styles.wrap}>
          <div className={styles.left}>
            <img src={`${process.env.GATSBY_MEDIA_URL}${t.story_image.filename_disk}`} alt={t.header_image.title} />
          </div>
          <div className={styles.right}>
            <p>{t.story_text}</p>
            <p>
              <strong>{t.story_who}</strong>
            </p>
          </div>
        </div>
      </section>
      <section className={styles.values}>
        <h1>{t.values_header}</h1>
        <hr />
        <div className={styles.wrap}>
          <div className={styles.value}>
            <img src={`${process.env.GATSBY_MEDIA_URL}${t.value_1_image.filename_disk}`} alt={t.header_image.title} />
            <h3>{t.value_1_header}</h3>
            <hr />
            <p>{t.value_1_text}</p>
          </div>
          <div className={styles.value}>
            <img src={`${process.env.GATSBY_MEDIA_URL}${t.value_2_image.filename_disk}`} alt={t.header_image.title} />
            <h3>{t.value_2_header}</h3>
            <hr />
            <p>{t.value_2_text}</p>
          </div>
          <div className={styles.value}>
            <img src={`${process.env.GATSBY_MEDIA_URL}${t.value_3_image.filename_disk}`} alt={t.header_image.title} />
            <h3>{t.value_3_header}</h3>
            <hr />
            <p>{t.value_3_text}</p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutUsPage;
